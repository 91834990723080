import { AMAZON_BASE_URL, MENUS } from './Constants';
import TitleSection from './TitleSection';
import { AppStore } from './types';
import withStoreConfig from './WithStoreConfig';

const Events: React.FC<{
    storeConfiguration?: AppStore['storeConfiguration'];
}> = ({ storeConfiguration }) => (
    <div
        id={MENUS.EVENTS.hash}
        className='events-section'
        style={{
            backgroundImage: `url("${AMAZON_BASE_URL}/store___${storeConfiguration.shortUniqueID}/template/event_bg.jpg")`
        }}>
        <div className='container'>
            <TitleSection title='Kommende Veranstaltungen' />
            <div className='content'>
                momentan sind keine Veranstaltungen anzuzeigen...
            </div>
        </div>
    </div>
);

export default withStoreConfig(Events);
