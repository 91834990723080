import { AMAZON_BASE_URL, MENUS } from './Constants';
import TitleSection from './TitleSection';
import { AppStore } from './types';
import withStoreConfig from './WithStoreConfig';

const AboutUs: React.FC<{ storeConfiguration?: AppStore['storeConfiguration'] }> = ({
    storeConfiguration
}) => (
    <div
        id={MENUS.ABOUT_US.hash}
        className='about-us-section'
        style={{
            backgroundImage: `url("${AMAZON_BASE_URL}/store___${storeConfiguration.shortUniqueID}/template/about_bg.png")`
        }}>
        <div className='container'>
            <TitleSection title='Über uns' />
            <div className='content'>
                <div className='description'>
                    <p className='uppercase'>
                        der perfekte Ort um griechische Küche und Gastlichkeit zu
                        genießen.
                    </p>
                    <p>
                        Erleben und genießen Sie die typisch traditionelle griechische
                        Küche. Für uns Griechen ist die Geselligkeit beim Essen genauso
                        wichtig wie die Speisen selbst. Deshalb ist unsere Taverna mehr
                        als ein Restaurant. Traditionelle Musik, Ouzo, Wein und
                        mediterrane Spezialitäten versetzen Sie direkt nach
                        Griechenland.
                        <br />
                        <br />
                        Lassen Sie sich als Gast des Hauses verwöhnen und von der
                        Qualität griechischer Spezialitäten überzeugen. Dabei legen wir
                        besonderen Wert auf frische Zutaten und eine abwechslungsreiche
                        und gesunde mediterrane Mittelmeer Küche. Begleitend zu Ihrem
                        Essen, wollen wir Ihnen gerne die Vielfalt der griechischen
                        Weinkultur näher bringen. Diese stammen bei uns ausschließlich
                        von griechischen Weingütern.
                        <br />
                        <br />
                        In unserem Restaurant bieten wir Platz für bis zu 40 Personen in
                        unserer Taverna. Für geschlossene Feiern wie Geburtstage,
                        Kommunionen, Weihnachtsfeiern, Familienfesten oder jede andere
                        Veranstaltung kontaktieren Sie uns, wir beraten Sie gern.
                    </p>
                </div>
            </div>
        </div>
    </div>
);

export default withStoreConfig(AboutUs);
