import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { Modal } from '@mui/material';
import FontAwesome from 'react-fontawesome';
import AboutUs from './AboutUs';
import Contact from './Contact';
import Footer from './Footer';
import Header from './Header';
import Reservation from './Reservation';
import Events from './Events';
import Menu from './Menu';
import { AMAZON_BASE_URL, MENUS } from './Constants';
import { fetchData } from './actions/Actions';
import { Store } from './types';
import Gallery from './Gallery';

const App: React.FC = () => {
    const [showModal, setShowModal] = React.useState<boolean>();
    const [open, setOpen] = React.useState<boolean>();
    const categories = useSelector(({ app }: Store) => app.categories);
    const storeConfiguration = useSelector(({ app }: Store) => app.storeConfiguration);
    const closures = useSelector(({ app }: Store) => app.closures);
    const dispatch = useDispatch();

    const currentClosures = React.useMemo(
        () =>
            closures.filter(({ effectiveByDate, endDate }) =>
                moment().isBetween(effectiveByDate, endDate, 'day', '[]')
            ),
        [closures]
    );

    React.useEffect(() => {
        dispatch(fetchData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (currentClosures.length) {
            setShowModal(true);
            setOpen(true);
        }
    }, [currentClosures.length]);

    const handleModalClose = React.useCallback(() => {
        setOpen(false);
    }, []);

    if (!storeConfiguration) {
        return null;
    }

    return (
        <div className='app-body'>
            {showModal && (
                <Modal
                    open={open}
                    onClose={handleModalClose}
                    classes={{ root: 'modal' }}>
                    <div className='vacation'>
                        <h2>
                            <FontAwesome name='times' onClick={handleModalClose} />
                        </h2>
                        {currentClosures.map(({ id, closureNotification }, i) => (
                            <React.Fragment key={id}>
                                <div className='closure-message'>
                                    {closureNotification}
                                </div>
                                {i !== currentClosures.length - 1 &&
                                currentClosures.length > 1 ? (
                                    <hr />
                                ) : null}
                            </React.Fragment>
                        ))}
                    </div>
                </Modal>
            )}
            <Header />
            <div
                className='fill-image'
                style={{
                    backgroundImage: `url("${AMAZON_BASE_URL}/store___${storeConfiguration.shortUniqueID}/template/lokal_1_withLogo.jpg")`
                }}
            />
            <AboutUs />
            <Menu
                title='Speisen'
                hash={MENUS.FOOD.hash}
                backgroundImage={`${AMAZON_BASE_URL}/store___${storeConfiguration.shortUniqueID}/template/dishes_bg.jpg`}
                categories={categories.filter(
                    ({ productCategoryMiscInfo }) => productCategoryMiscInfo.isFood
                )}
            />
            <Menu
                title='Getränke'
                hash={MENUS.DRINKS.hash}
                backgroundImage={`${AMAZON_BASE_URL}/store___${storeConfiguration.shortUniqueID}/template/drinks_bg.jpg`}
                categories={categories.filter(
                    ({ productCategoryMiscInfo }) => productCategoryMiscInfo.isDrinks
                )}
            />
            <Reservation />
            <Gallery />
            <Events />
            <Contact />
            <Footer />
        </div>
    );
};

export default App;
