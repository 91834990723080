import { AMAZON_BASE_URL } from './Constants';
import { AppStore } from './types';
import withStoreConfig from './WithStoreConfig';

const TitleSection: React.FC<{
    title: string;
    storeConfiguration?: AppStore['storeConfiguration'];
}> = ({ title, storeConfiguration }) => (
    <div className='title-section'>
        <h1>{title}</h1>
        <img
            src={`${AMAZON_BASE_URL}/store___${storeConfiguration.shortUniqueID}/template/separator_1.png`}
            alt='Underline'
        />
    </div>
);

export default withStoreConfig(TitleSection);
