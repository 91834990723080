import { TextField } from '@mui/material';
import React from 'react';
import moment from 'moment-timezone';
import { Field, Form, FormRenderProps } from 'react-final-form';
import FontAwesome from 'react-fontawesome';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useDispatch, useSelector } from 'react-redux';
import { FormApi } from 'final-form';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { AMAZON_BASE_URL, MENUS, required } from './Constants';
import TitleSection from './TitleSection';
import { reserveTable } from './actions/Actions';
import Anchor from './Anchor';
import { AppStore, Store } from './types';
import withStoreConfig from './WithStoreConfig';

type FormValues = {
    email: string;
    date: moment.Moment;
    numberOfPersons: number;
    name: string;
    comment: string;
    phoneNumber: string;
};

const WORKING_DAYS = [1, 2, 3, 4, 5, 6];

const shouldDisableDate = (closures: AppStore['closures'], d: moment.Moment) =>
    !WORKING_DAYS.includes(d.day()) ||
    closures.some(({ startDate, endDate }) =>
        d.isBetween(startDate, endDate, 'day', '[]')
    );

const FormDateTimePicker = ({ input }) => {
    const closures = useSelector(({ app }: Store) => app.closures);

    const handleDate = React.useCallback(
        (d: moment.Moment) => shouldDisableDate(closures, d),
        [closures]
    );

    return (
        <DateTimePicker
            {...input}
            inputFormat='DD.MM.yyyy HH:mm'
            disablePast
            showTodayButton
            disableMaskedInput
            shouldDisableDate={handleDate}
            // eslint-disable-next-line react/jsx-no-bind
            renderInput={params => (
                <TextField {...params} className='date-picker field-input' />
            )}
        />
    );
};

const Reservation: React.FC<{
    storeConfiguration?: AppStore['storeConfiguration'];
}> = ({ storeConfiguration }) => {
    const dispatch: ThunkDispatch<Store, unknown, AnyAction> = useDispatch();
    const closures = useSelector(({ app }: Store) => app.closures);

    const renderReservationForm = React.useCallback(
        ({ handleSubmit, validating, submitting }: FormRenderProps) => (
            <form className='form reservation-form' onSubmit={handleSubmit}>
                <div className='one-line'>
                    <Field name='email' validate={required}>
                        {({ input, meta }) => (
                            <div className='field-input wide'>
                                <input {...input} placeholder='Email' type='email' />
                                {meta.touched && meta.error && (
                                    <FontAwesome
                                        name='exclamation-circle'
                                        title={meta.error}
                                        className='error'
                                    />
                                )}
                            </div>
                        )}
                    </Field>
                    <Field name='numberOfPersons' validate={required}>
                        {({ input, meta }) => (
                            <div className='field-input'>
                                <input {...input} type='number' min={1} max={40} />
                                {meta.touched && meta.error && (
                                    <FontAwesome
                                        name='exclamation-circle'
                                        title={meta.error}
                                        className='error'
                                    />
                                )}
                            </div>
                        )}
                    </Field>
                    <Field name='date' component={FormDateTimePicker} />
                </div>
                <div className='one-line'>
                    <Field name='name' validate={required}>
                        {({ input, meta }) => (
                            <div className='field-input wide'>
                                <input {...input} placeholder='Name' />
                                {meta.touched && meta.error && (
                                    <FontAwesome
                                        name='exclamation-circle'
                                        title={meta.error}
                                        className='error'
                                    />
                                )}
                            </div>
                        )}
                    </Field>
                    <Field name='phoneNumber' validate={required}>
                        {({ input, meta }) => (
                            <div className='field-input wide'>
                                <input
                                    {...input}
                                    placeholder='Telefonnummer'
                                    type='tel'
                                />
                                {meta.touched && meta.error && (
                                    <FontAwesome
                                        name='exclamation-circle'
                                        title={meta.error}
                                        className='error'
                                    />
                                )}
                            </div>
                        )}
                    </Field>
                </div>
                <div className='one-line'>
                    <Field name='comment'>
                        {({ input, meta }) => (
                            <div className='field-input comment'>
                                <input {...input} placeholder='Kommentar' />
                                {meta.touched && meta.error && (
                                    <FontAwesome
                                        name='exclamation-circle'
                                        title={meta.error}
                                        className='error'
                                    />
                                )}
                            </div>
                        )}
                    </Field>
                </div>
                <button
                    type='submit'
                    className='reserve'
                    disabled={validating || submitting}>
                    RESERVIEREN
                </button>
                <div className='note'>
                    VORSICHT: Bitte überprüfen Sie Ihre E-Mails und bestätigen Sie Ihre
                    Reservierung aus Sicherheitsgründen.
                </div>
                <div className='note'>
                    ATTENTION: Please check your email and verify your reservation for
                    security purposes.
                </div>
            </form>
        ),
        []
    );

    const handleReservation = React.useCallback(
        (values: FormValues, form: FormApi) =>
            dispatch(
                reserveTable({
                    ...values,
                    numberOfPersons: Number(values.numberOfPersons),
                    date: `${values.date.format('YYYY-MM-DDT')}${values.date.format(
                        'HH:mm'
                    )}Z`
                })
            ).then(() => {
                form.reset({
                    numberOfPersons: 1,
                    date: moment()
                });

                ['name', 'email', 'numberOfPersons', 'phoneNumber'].forEach(key => {
                    form.resetFieldState(key);
                });
            }),
        [dispatch]
    );

    return (
        <div
            className='reservation-section'
            id={MENUS.RESERVATION.hash}
            style={{
                backgroundImage: `url("${AMAZON_BASE_URL}/store___${storeConfiguration.shortUniqueID}/template/reservation_bg.jpg")`
            }}>
            <div className='container'>
                <TitleSection title='Tisch Reservierung' />
                <div className='reservation-info'>
                    <div style={{ fontSize: 14 }}>
                        <div className='opening-hours-label'>Öffnungszeiten</div>
                        <span>Montag-Freitag-Samstags</span>
                        <br />
                        <span>17:00-23:00</span>
                        <br />
                        <br />
                        <span>Dienstag-Mittwoch-Donnerstag</span>
                        <br />
                        <span>11:30-14:30 und 17:00-23:00</span>
                        <br />
                        <br />
                        <span style={{ fontWeight: 'bold' }}>
                            Sonntag und Feiertage
                        </span>
                        <br />
                        <span style={{ fontWeight: 'bold' }}>Geschlossen</span>
                    </div>
                    <br />
                    <div className='special-event'>
                        (Für geschlossene Gesellschaften gerne geöffnet)
                    </div>
                    <div className='more-info'>
                        - Für weitere Informationen, lesen Sie bitte die Sektion{' '}
                        <Anchor hash={MENUS.EVENTS.hash} className='events-anchor'>
                            'Kommende Veranstaltungen'
                        </Anchor>{' '}
                        ↓. -
                    </div>
                    <div className='phone-numbers'>
                        <FontAwesome name='phone' className='phone' />
                        <a href='tel:+436769254666'>+43 676 9254666</a>
                    </div>
                </div>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Form
                        render={renderReservationForm}
                        onSubmit={handleReservation}
                        initialValues={{
                            numberOfPersons: 1,
                            date: shouldDisableDate(closures, moment())
                                ? moment().add(1, 'day')
                                : moment()
                        }}
                    />
                </LocalizationProvider>
            </div>
        </div>
    );
};

export default withStoreConfig(Reservation);
