import { Action, ActionType } from '../actions/actionTypes';
import STORE from '../store';
import { AppStore } from '../types';

// eslint-disable-next-line default-param-last
const reducer = (state = STORE.app, action: Action): AppStore => {
    switch (action.type) {
        case ActionType.REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(
                    ({ key }) => key !== action.payload
                )
            };
        case ActionType.ENQUEUE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.concat(action.payload)
            };
        case ActionType.SET_CATEGORIES:
            return { ...state, categories: action.payload };
        case ActionType.SET_PRODUCTS:
            return { ...state, products: action.payload };
        case ActionType.SET_ALLERGENS:
            return { ...state, allergens: action.payload };
        case ActionType.SET_GALLERY_IMAGES:
            return { ...state, galleryImages: action.payload };
        case ActionType.SET_STORE_CONFIGURATION:
            return { ...state, storeConfiguration: action.payload };
        case ActionType.SET_CLOSURES:
            return { ...state, closures: action.payload };
        default:
            return state;
    }
};

export default reducer;
