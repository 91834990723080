import React from 'react';
import { Form, Field, FormRenderProps } from 'react-final-form';
import FontAwesome from 'react-fontawesome';
import { FormApi } from 'final-form';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { AMAZON_BASE_URL, MENUS, required } from './Constants';
import * as Api from './Api';
import { AppStore } from './types';
import { enqueueNotification } from './actions/Actions';
import TitleSection from './TitleSection';
import withStoreConfig from './WithStoreConfig';

type FormValues = {
    email: string;
    fullName: string;
};

const Footer: React.FC<{ storeConfiguration?: AppStore['storeConfiguration'] }> = ({
    storeConfiguration
}) => {
    const dispatch = useDispatch();

    const subscribeToNewsletter = React.useCallback(
        (values: FormValues, form: FormApi) =>
            Api.registerToNewsletter(values).then(res => {
                if (res.status === 200) {
                    dispatch(
                        enqueueNotification({
                            key: new Date().getTime(),
                            message:
                                '✓ Sie haben sich erfolgreich für unseren Newsletter registriert.',
                            options: {
                                variant: 'success'
                            }
                        })
                    );

                    form.reset({});
                    ['email', 'fullName'].forEach(key => {
                        form.resetFieldState(key);
                    });
                } else {
                    res.text().then(err => {
                        dispatch(
                            enqueueNotification({
                                key: new Date().getTime(),
                                message: `Ein Fehler passiert... Code: ${res.status}${
                                    err ? `, Message: ${err}` : ''
                                }`,
                                options: {
                                    variant: 'error'
                                }
                            })
                        );
                    });
                }
            }),
        [dispatch]
    );

    const renderForm = React.useCallback(
        ({ handleSubmit, submitting, validating }: FormRenderProps) => (
            <form onSubmit={handleSubmit} className='newsletter-form form'>
                <Field name='fullName' validate={required}>
                    {({ input, meta }) => (
                        <div className='field-input'>
                            <input {...input} placeholder='Name' />
                            {meta.touched && meta.error && (
                                <FontAwesome
                                    name='exclamation-circle'
                                    title={meta.error}
                                    className='error'
                                />
                            )}
                        </div>
                    )}
                </Field>
                <Field name='email' validate={required}>
                    {({ input, meta }) => (
                        <div className='field-input'>
                            <input {...input} placeholder='Email' type='email' />
                            {meta.touched && meta.error && (
                                <FontAwesome
                                    name='exclamation-circle'
                                    title={meta.error}
                                    className='error'
                                />
                            )}
                        </div>
                    )}
                </Field>
                <button type='submit' disabled={submitting || validating}>
                    <FontAwesome name='envelope-o' className='email' />
                </button>
            </form>
        ),
        []
    );

    return (
        <div
            id={MENUS.IMPRESSUM.hash}
            className='footer'
            style={{
                backgroundImage: `url("${AMAZON_BASE_URL}/store___${storeConfiguration.shortUniqueID}/template/newsletter_bg.png")`
            }}>
            <div className='newletter'>
                <div className='container'>
                    <TitleSection title='Newsletter' />
                    <div className='form'>
                        <Form render={renderForm} onSubmit={subscribeToNewsletter} />
                        <div className='social-media'>
                            <a
                                href='https://www.facebook.com/meze.meze.vienna/'
                                target='_blank'
                                rel='noreferrer'>
                                <FontAwesome
                                    name='facebook'
                                    className='link facebook'
                                />
                            </a>
                            <a
                                href='https://www.instagram.com/mezemeze_vienna/'
                                target='_blank'
                                rel='noreferrer'>
                                <FontAwesome
                                    name='instagram'
                                    className='link instagram'
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-info'>
                <p>
                    MEZE MEZE, Cafe - Bar - Restaurant
                    <br />
                    Topalidou Gastro-, Catering- und Handels GmbH
                    <br />
                    Ungargasse 31, AT-1030 Wien
                    <br />
                    Nr: 01 8905578 - Email:{' '}
                    <a href='mailto:info@meze-meze.at'>info@meze-meze.at</a>
                    <br />
                    ATU 72661238
                    <br />
                    <br />© Meze Meze Restaurant {moment().year()}. All rights reserved.
                    Design & Development:{' '}
                    <a href='http://personalized.at' target='_blank' rel='noreferrer'>
                        Personalized.at
                    </a>
                    .
                </p>
            </div>
        </div>
    );
};

export default withStoreConfig(Footer);
