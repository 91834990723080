import { Store } from './types';

const STORE: Store = {
    app: {
        notifications: [],
        categories: [],
        products: [],
        allergens: [],
        galleryImages: [],
        storeConfiguration: null,
        closures: []
    }
};

export default STORE;
