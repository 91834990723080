import { SnackbarKey } from 'notistack';
import { AppStore, INotification } from '../types';
import { Action, ActionType, ThunkAction } from './actionTypes';
import * as Api from '../Api';

const getErrorNotification = (err: string): INotification => ({
    key: new Date().getTime(),
    message: err,
    options: {
        variant: 'error'
    }
});

export const removeNotification = (key: SnackbarKey): Action => ({
    type: ActionType.REMOVE_NOTIFICATION,
    payload: key
});

export const enqueueNotification = (
    notification: AppStore['notifications'][number]
): Action => ({
    type: ActionType.ENQUEUE_NOTIFICATION,
    payload: notification
});

const setCategories = (categories: AppStore['categories']): Action => ({
    type: ActionType.SET_CATEGORIES,
    payload: categories
});

const setProducts = (products: AppStore['products']): Action => ({
    type: ActionType.SET_PRODUCTS,
    payload: products
});

const setAllergens = (allergens: AppStore['allergens']): Action => ({
    type: ActionType.SET_ALLERGENS,
    payload: allergens
});

const setGalleryImages = (images: AppStore['galleryImages']): Action => ({
    type: ActionType.SET_GALLERY_IMAGES,
    payload: images
});

const setStoreConfiguration = (
    configuration: AppStore['storeConfiguration']
): Action => ({
    type: ActionType.SET_STORE_CONFIGURATION,
    payload: configuration
});

const setClosures = (closures: AppStore['closures']): Action => ({
    type: ActionType.SET_CLOSURES,
    payload: closures
});

export const reserveTable =
    (values: Record<string, unknown>): ThunkAction<Promise<void>> =>
    async dispatch => {
        try {
            const { token } = await Api.login();
            return Api.reserveTable(token, values).then(
                () => {
                    dispatch(
                        enqueueNotification({
                            key: new Date().getTime(),
                            message:
                                '✓ Ihre Tischreservierung wird erfolgreih gespeichert.',
                            options: {
                                variant: 'success'
                            }
                        })
                    );
                },
                err => {
                    dispatch(enqueueNotification(getErrorNotification(err.message)));
                    return null;
                }
            );
        } catch (err) {
            dispatch(enqueueNotification(getErrorNotification(err)));
            return null;
        }
    };

export const fetchData = (): ThunkAction => async dispatch => {
    try {
        const { token } = await Api.login();
        Promise.all([
            Api.getCategories(token),
            Api.getProducts(token),
            Api.getAllergens(token),
            Api.getGalleryImages(token),
            Api.getStoreConfiguration(token),
            Api.getClosures(token)
        ]).then(
            ([
                categories,
                products,
                allergens,
                galleryImages,
                storeConfiguration,
                closures
            ]) => {
                dispatch(setCategories(categories));
                dispatch(setProducts(products));
                dispatch(setAllergens(allergens));
                dispatch(setGalleryImages(galleryImages));
                dispatch(setStoreConfiguration(storeConfiguration));
                dispatch(setClosures(closures));
            }
        );
    } catch (err) {
        dispatch(enqueueNotification(getErrorNotification(err)));
    }
};
