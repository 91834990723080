import React from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import FontAwesome from 'react-fontawesome';
import { FormApi } from 'final-form';
import { useDispatch } from 'react-redux';
import { AMAZON_BASE_URL, MENUS, required } from './Constants';
import * as Api from './Api';
import { AppStore } from './types';
import { enqueueNotification } from './actions/Actions';
import withStoreConfig from './WithStoreConfig';

type FormValues = {
    name: string;
    email: string;
    message: string;
};

const Contact: React.FC<{ storeConfiguration?: AppStore['storeConfiguration'] }> = ({
    storeConfiguration
}) => {
    const dispatch = useDispatch();

    const handleContact = React.useCallback(
        (values: FormValues, form: FormApi) => {
            Api.contact(values)
                .then(() => {
                    form.reset();

                    ['name', 'email', 'message'].forEach(key => {
                        form.resetFieldState(key);
                    });

                    dispatch(
                        enqueueNotification({
                            key: new Date().getTime(),
                            message: 'Ihre Nachricht wurde erfolgreich gesendet',
                            options: { variant: 'success' }
                        })
                    );
                })
                .catch(err => {
                    dispatch(
                        enqueueNotification({
                            key: new Date().getTime(),
                            message: err.message,
                            options: { variant: 'error' }
                        })
                    );
                });
        },
        [dispatch]
    );

    const renderContactForm = React.useCallback(
        ({ handleSubmit, submitting, validating }: FormRenderProps) => (
            <form onSubmit={handleSubmit} className='contact-form form'>
                <div className='one-line'>
                    <Field name='name' validate={required}>
                        {({ input, meta }) => (
                            <div className='field-input'>
                                <input {...input} placeholder='Name' />
                                {meta.touched && meta.error && (
                                    <FontAwesome
                                        name='exclamation-circle'
                                        title={meta.error}
                                        className='error'
                                    />
                                )}
                            </div>
                        )}
                    </Field>
                    <Field name='email' validate={required}>
                        {({ input, meta }) => (
                            <div className='field-input'>
                                <input {...input} placeholder='Email' type='email' />
                                {meta.touched && meta.error && (
                                    <FontAwesome
                                        name='exclamation-circle'
                                        title={meta.error}
                                        className='error'
                                    />
                                )}
                            </div>
                        )}
                    </Field>
                </div>
                <Field name='message' validate={required}>
                    {({ input, meta }) => (
                        <div className='field-input'>
                            <textarea {...input} placeholder='Text' />
                            {meta.touched && meta.error && (
                                <FontAwesome
                                    name='exclamation-circle'
                                    title={meta.error}
                                    className='error'
                                />
                            )}
                        </div>
                    )}
                </Field>
                <button type='submit' disabled={submitting || validating}>
                    UNS KONTAKTIEREN
                </button>
            </form>
        ),
        []
    );

    return (
        <div
            id={MENUS.CONTACT.hash}
            className='contact-section'
            style={{
                backgroundImage: `url("${AMAZON_BASE_URL}/store___${storeConfiguration.shortUniqueID}/template/lokal_1contact.jpg")`
            }}>
            <div className='wrapper'>
                <div className='contact-container'>
                    <iframe
                        src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2659.2674459261566!2d16.3853365!3d48.2014643!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d0771c504cd27%3A0x3704d9908decd37a!2sMeze%20Meze!5e0!3m2!1sen!2sat!4v1656249366810!5m2!1sen!2sat'
                        width='570'
                        height='400'
                        style={{ border: 0 }}
                        allowFullScreen
                        loading='lazy'
                        referrerPolicy='no-referrer-when-downgrade'
                        title='map'
                    />
                    <div className='contact-info'>
                        <div className='title-section'>
                            <h1>Kontakt</h1>
                            <Form render={renderContactForm} onSubmit={handleContact} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withStoreConfig(Contact);
